import React from "react"
import { RichText } from "prismic-reactjs"
import Image from "./image"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import LocalizedLink from "./localizedLink"

function CardIntegration({ integration }) {
  const { node } = integration
  return (
    <LocalizedLink to={`integration/${node._meta.uid}`}>
      <div css={cardIntegrationCss}>
        <article className="media box">
          <figure className="media-left">
            <div className="image is-64x64">
              {node.main_image && (
                <Image sharp={node.main_imageSharp} image={node.main_image} />
              )}
            </div>
          </figure>
          <div className="media-content">
            <h4 className="title is-6 has-text-weight-medium">
              {RichText.asText(node.title)}
            </h4>
            <div className="subtitle is-6 has-text-weight-light">
              {node._meta.tags
                .filter(tag => !tag.startsWith("feature"))
                .map(category => (
                  <small key={category} className="is-small">
                    {category}{" "}
                  </small>
                ))}
            </div>
          </div>
        </article>
      </div>
    </LocalizedLink>
  )
}

export default CardIntegration

const cardIntegrationCss = css`
  .box {
    border: 1px solid ${colors.lightgray};
    box-shadow: none;
  }

  @media (min-width:1024px) and (max-width: 1215px) {
    .media-content .is-6 {
      font-size: .65rem;
    }
  }
`
